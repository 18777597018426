html {
    height: 100%
}

body {
    height: inherit
}

#root {
    height: inherit
}

.draggable {
    top: auto !important;
    left: auto !important;
}

.mantine-Modal-header {
    z-index: 300;
}

@media only screen and (max-width: 600px) {
    select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
        font-size: 16px;
    }
}
